<template>
  <div class="loog-for-conteiner">
    <div class="filter-content">
      <!-- <el-form inline label-width="120px">
        <el-form-item label="产品名称：">
          <el-input class="input-width" v-model="filterForm.productName" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="规格型号：">
          <el-input class="input-width" v-model="filterForm.productSpec" placeholder="请输入规格型号"></el-input>
        </el-form-item>
        <el-form-item label="竞买商：">
          <el-input class="input-width" v-model="filterForm.clientName" placeholder="请输入竞买商"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button size="small" @click="getProductList">搜索</el-button>
        </el-form-item>
      </el-form> -->
      <!-- <FilterCondition title="所属类型"
                       :data='categoryList'
                       @on-go="onSelectCategory"></FilterCondition> -->
      <!-- <div class="screen-status">
        <span class="tit">状态</span>
        <span>：</span>
        <div class="status-box">
          <p @click="selectStatus('')"
             class="status-box-all">全部</p>
        </div>
      </div> -->
      <div class="status-tab">
        <div class="status-tab-item" :class="{active: filterForm.bathType === '0'}" @click="selectStatus('0')">正在进行</div>
        <div class="status-tab-item" :class="{active: filterForm.bathType === '1'}" @click="selectStatus('1')">即将开始</div>
        <div class="status-tab-item" :class="{active: filterForm.bathType === '2'}" @click="selectStatus('2')">历史批次</div>
      </div>
    </div>
    <div class="time-box" v-if="filterForm.bathType === '0'">
      <span v-if="endTime.timer">
        本轮报价结束时间:
        <span class="time" v-if="endTime.day > 0">{{ endTime.day }} </span>
        <span class="unit" v-if="endTime.day > 0">天</span>
        <span class="time"> {{ endTime.hour }} </span>
        <span class="unit">时</span>
        <span class="time"> {{ endTime.minute }} </span>
        <span class="unit">分</span>
        <span class="time"> {{ endTime.second }} </span>
        <span class="unit">秒</span>
      </span>
    </div>
    <div class="time-box" v-if="filterForm.bathType === '1'" style="background: rgb(8, 135, 242);">
      <span v-if="startTime.timer">
        下轮报价开始时间:
        <span class="time" v-if="startTime.day > 0">{{ startTime.day }} </span>
        <span class="unit" v-if="startTime.day > 0">天</span>
        <span class="time"> {{ startTime.hour }} </span>
        <span class="unit">时</span>
        <span class="time"> {{ startTime.minute }} </span>
        <span class="unit">分</span>
        <span class="time"> {{ startTime.second }} </span>
        <span class="unit">秒</span>
      </span>
    </div>
    <!-- 正在进行和即将开始 -->
    <div class="card-content" v-if="filterForm.bathType !== '2'" style="padding-bottom: 55px;">
      <template v-if="tableData.length && ((filterForm.bathType === '0' && endTime.timer) || (filterForm.bathType === '1' && startTime.timer))">
        <div class="card-item" v-for="(item, index) in tableData" :class="{active: selectProductIds.includes(item.id) && filterForm.bathType === '0'}" :key="item.id" @click="toDetail(item.id, index)">
          <img class="card-image" :src="item.picUrl || 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=587861744,2176912391&fm=26&gp=0.jpg'" />
          <div class="card-info">
            <div class="card-info-title">{{ item.productName }}</div>
            <div class="card-info-item">
              <div class="title">规格型号</div>
              ：
              <div class="text">{{ item.productSpec }}</div>
            </div>
            <div class="card-info-item">
              <div class="title">数量</div>
              ：
              <div class="text">{{ item.reqNum }}{{ item.unit }}</div>
            </div>
            <div class="card-info-item">
              <div class="title">求购价</div>
              ：
              <div class="price">
                <span>{{ item.maxPrice | toFixed}}</span>
              </div>
            </div>
            <div class="card-info-item">
              <div class="title">商家报价</div>
              ：
              <div class="price">
                <span>{{ item.minPrice | toFixed}}</span>
              </div>
            </div>
          </div>
          <div class="card-status" v-if="filterForm.bathType === '0'" @click.stop="selectProduct(item)">
            <img class="select" v-if="item.curUserMin === 0" src="../image/icon-anewPrice.png" />
            <img class="select" v-else-if="item.curUserMin === 1" src="../image/icon-lowestPrice.png" />
          </div>
          <div class="card-select" v-if="filterForm.bathType === '0'" @click.stop="selectProduct(item)">
            <img class="select" v-if="selectProductIds.includes(item.id)" src="../image/select.png" />
            <img class="select" v-else src="../image/no-select.png" />
          </div>
        </div>
      </template>
      <NoDataFound v-else style="margin-bottom:60px;"></NoDataFound>
    </div>
    <!-- 历史批次 -->
    <div class="history-card-content" v-else style="padding-bottom: 55px;">
      <template v-if="tableData.length">
        <div class="card-batch" v-for="(item, index) in tableData" :key="index">
          <div class="card-time">{{ item.bathTime.startTime }} - {{ item.bathTime.endTime }}</div>
          <div class="card-list">
            <div class="card-item" v-for="(p, pidx) in item.list" :key="p.id" @click="toDetail(p.id, pidx)">
              <img class="card-image" :src="p.picUrl || 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=587861744,2176912391&fm=26&gp=0.jpg'" />
              <div class="card-info">
                <div class="card-info-title">{{ p.productName }}</div>
                <div class="card-info-item">
                  <div class="title">规格型号</div>
                  ：
                  <div class="text">{{ p.productSpec }}</div>
                </div>
                <div class="card-info-item">
                  <div class="title">数量</div>
                  ：
                  <div class="text">{{ p.reqNum }}{{ p.unit }}</div>
                </div>
                <div class="card-info-item">
                  <div class="title">求购价</div>
                  ：
                  <div class="price">
                    ￥<span>{{ p.maxPrice | toFixed }}</span>
                  </div>
                </div>
                <div class="card-info-item">
                  <div class="title">商家报价</div>
                  ：
                  <div class="price">
                    ￥<span>{{ p.minPrice | toFixed }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <NoDataFound v-else style="margin-bottom:60px;"></NoDataFound>
    </div>
    <!-- 本轮暂时最低价 -->
    <!-- <div class="title-wrap"
         v-if="isLogin && filterForm.bathType === '0'">本轮暂时最低报价</div>
    <div class="card-content"
         style="padding-bottom: 17px;background: #fff;"
         v-if="isLogin && filterForm.bathType === '0'">
      <template v-if="quteSuccessList === null || quteSuccessList.length !== 0">
        <div class="card-item"
             v-for="(item, index) in quteSuccessList"
             :key="item.id"
             @click="toDetail(item.productId, index)">
          <img class="card-image"
               :src="item.picUrl || 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=587861744,2176912391&fm=26&gp=0.jpg'" />
          <div class="card-info">
            <div class="card-info-title">{{item.productName}}</div>
            <div class="card-info-item">
              <div class="title">规格型号</div>：
              <div class="text">{{item.specName}}</div>
            </div>
            <div class="card-info-item">
              <div class="title">数量</div>：
              <div class="text">{{item.num}}{{item.unit}}</div>
            </div>
            <div class="card-info-item">
              <div class="title">求购价</div>：
              <div class="price">￥<span>{{item.maxPrice}}</span></div>
            </div>
            <div class="card-info-item">
              <div class="title">商家报价</div>：
              <div class="price">￥<span>{{item.minPrice}}</span></div>
            </div>
          </div>
          <div class="card-select">
            <img class="done"
                 src="../image/done.png" />
          </div>
        </div>
      </template>
      <empty v-else
             text="暂无数据" />
    </div> -->

    <div class="right-btn">
      <template>
        <div class="btn-item hover" :class="[selectProductIds.length ? 'hover' : 'no-select']" @click="showQuoteDialog"><span>一键报价</span></div>
        <div
          class="
             line"
        ></div>
      </template>
      <template>
        <div class="btn-item" @click="cancelSelect"><span>取消勾选</span></div>
        <div class="line"></div>
      </template>
      <template>
        <div class="btn-item" @click="quoteManage.ruleInfoDialogVisible = true"><span>规则须知</span></div>
        <div class="line"></div>
      </template>
      <div class="btn-item" @click="refreshPage">
        <i class="el-icon-refresh"></i>
        <span>刷新</span>
      </div>
    </div>
    <!-- <div class="footer-content">
      <div class="pagination">
        <el-pagination background
                       layout="prev, pager, next"
                       :total='pageManage.total'
                       :page-size='pageManage.limit'
                       :current-page.sync="pageManage.page"
                       @current-change="changeCurrentPage">
        </el-pagination>
      </div>
      <div class="quote-btn" :class="{'no-select': !selectProductIds.length}" @click="quoteBtn">一键报价</div>
    </div>
      <div class="quote-btn"
           :class="{'no-select': !selectProductIds.length}"
           @click="quoteBtn">一键报价</div>
    </div> -->
    <!-- 证书组件 -->
    <CFCATool id="CFCAId" ref="CFCARef" @getCertInfo="certInfo = $event"/>
    <!-- 规则须知 -->
    <el-dialog title="规则须知" :visible.sync="quoteManage.ruleInfoDialogVisible" width="520px">
      <div class="rule-info">
        <ul>
          <li>竞买产品中买家提供产品的最高求购价，此后供应商在此价格的基础上进行减价形式的报价；</li>
          <li>供应商所报价不能高于当前最低报价，否则视为报价不成功；</li>
          <li>一键报价功能在选择了多个竞买产品时，按照所选产品中的报价最低的产品为标准，为所选产品的报价和价格调整幅度；</li>
          <li>对于既是供应商又是买家的用户，用户不能给自己企业的产品报价，否则视为报价不成功；</li>
          <li>竞买产品报价成功的结果为产品有效期内的最低报价，一次报价成功不代表产品成交；</li>
          <li>竞买产品报价成功之后，系统将自动根据报价情况，在产品有效期结束之后选择最低报价成交，届时将会以短信通知的形式发送到用户预留的手机号。</li>
        </ul>
      </div>
    </el-dialog>
    <!-- 一键报价 -->
    <el-dialog title="一键报价" :visible.sync="quoteManage.quoteDialogVisible" width="380px">
      <el-form :model="quoteManage.quoteForm" :rules="quoteManage.quoteFormRules" ref="quoteForm" inline style="text-align: center;">
        <el-form-item prop="price" label="报价(元):">
          <div class="num-box">
            <div class="min" :class="{disable: quoteManage.miunsBtnDisable}" @click="minusAmount"><i class="el-icon-minus"></i></div>
            <div class="num" type="text">{{ quoteManage.quoteForm.price }}</div>
            <div class="add" :class="{disable: quoteManage.addBtnDisable}" @click="addAmount"><i class="el-icon-plus"></i></div>
          </div>
          <!-- <el-input v-model="quoteManage.quoteForm.price"
                    placeholder="请输入报价"
                    style="width:150px;" /> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div style="text-align: center;">
          <el-button @click="quoteManage.quoteDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="quoteBtn">确认报价</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 报价结果 -->
    <el-dialog title="报价结果" :visible.sync="quoteManage.quoteResultDialogVisible" @closed="handleCloseResult" width="300px">
      <div style="font-size: 14px;text-align: center;">
        报价成功：<span style="color: green;">{{ quoteManage.successNum }} </span>件， 报价失败：<span style="color: red;">{{ quoteManage.fallNum }} </span>件
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align: center;">
          <el-button @click="quoteManage.quoteResultDialogVisible = false">关闭({{ quoteManage.closeResultTimeText }}s)</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CFCATool from '@/components/CFCATool';
import { formatNewDate } from '@/utils/util';
import FilterCondition from '@/components/FilterCondition';
import NoDataFound from '@/components/NoDataFound';
import empty from '../component/empty';
import { regRule } from '@/utils/validate';
import {
  getProductList,
  categoryAll,
  getQuteSuccessList,
  getProductHistoryList,
  addProductQuote
} from '../api';
let that =''
export default {
  components: {
    FilterCondition,
    NoDataFound,
    CFCATool,
    empty
  },
     beforeCreate: function () {
        that = this
    },
   filters:{
    toFixed:function(data){
      data=that.formatMoney(data)
      return data
    },

  },
  data() {
    let validatePrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error('请输入报价'));
      } else if (!regRule.number1.test(value)) {
        return callback(new Error('请输入正确的报价'));
      } else {
        callback();
      }
    };
    return {
      isLogin: Boolean(localStorage.getItem('loginName')),
      categoryList: [],
      filterForm: {
        // apprvStatus: "P",
        // productName: '',
        // productSpec: '',
        // clientName: '',
        // startType: '1',
        // categoryId: '',
        bathType: '0'
      },
      tableData: [],
      pageManage: {
        page: 1,
        limit: 99,
        total: 0
      },
      quoteManage: {
        quoteForm: {
          price: ''
        },
        quoteFormRules: {
          price: [{required: true, validator: validatePrice, trigger: 'blur'}]
        },
        successNum: 0,
        fallNum: 0,
        quoteDialogVisible: false,
        quoteResultDialogVisible: false,
        ruleInfoDialogVisible: false,
        closeResultTimer: null,
        closeResultTimeText: 10,
        miunsBtnDisable: false,
        addBtnDisable: true,
        selectMinPriceProduct: null // 选中的最低价产品
      },
      startTime: {
        day: 0,
        hour: '00',
        minute: '00',
        second: '00',
        timer: null
      },
      endTime: {
        day: 0,
        hour: '00',
        minute: '00',
        second: '00',
        timer: null
      },
      quteSuccessList: [],
      productHistoryList: [],
      categoryList: [],
      currentActiveIndex: null,
      selectProductIds: JSON.parse(sessionStorage.getItem('loogForQuoteProductIds') || '[]'),
      selectProductList: JSON.parse(sessionStorage.getItem('loogForQuoteProductList') || '[]'),
      certInfo: '',
      timer: null
    };
  },
  created() {
    this.getProductList();
    this.initTimer();
  },
  beforeDestroy() {
    clearTimeout(this.startTime.timer);
    clearTimeout(this.endTime.timer);
    clearInterval(this.timer);
    this.startTime.timer = null;
    this.endTime.timer = null;
    this.timer = null;
  },
  methods: {
    initTimer() {
      this.timer = setInterval(() => {
        if (this.filterForm.bathType === '0') {
          let requests = [getProductList({...this.pageManage, ...this.filterForm})];

          Promise.all(requests).then(res => {
            if (res[0].errno === 0) {
              this.tableData = res[0].data.repProductList || [];
              let batchTime = sessionStorage.getItem('loogForBatchTime'); // 批次时间
              let timeRang = res[0].data.time_range;
              this.tableData.map(p=>{
                  // p.maxPrice=JSON.parse(JSON.stringify(this.formatMoney(p.maxPrice)))
                  // p.minPrice=JSON.parse(JSON.stringify(this.formatMoney(p.minPrice)))
              })
              if (timeRang) {
                // 如果批次不同则清理掉勾选商品的缓存
                if (batchTime) {
                  if (batchTime !== timeRang.startTime) {
                    sessionStorage.setItem('loogForBatchTime', timeRang.startTime);
                    this.cancelSelect();
                  }
                } else {
                  sessionStorage.setItem('loogForBatchTime', timeRang.startTime);
                }
                // 定时器过期重置
                if (this.filterForm.bathType === '0' && !this.endTime.timer) {
                  this.countdown(timeRang.endTime, this.endTime);
                } else if (this.filterForm.bathType === '1' && !this.startTime.timer) {
                  this.countdown(timeRang.startTime, this.startTime);
                }
              }
            }
          });
        }
      }, 1000);
    },
    isStart(startTime) {
      if (startTime) {
        let date = formatNewDate(startTime);
        return date.getTime() < new Date().getTime();
      } else {
        return true;
      }
    },
    getProductList(isLoop) {
      getProductList({
        ...this.pageManage,
        ...this.filterForm
      }).then(res => {
        if (res.errno === 0) {
          this.tableData = res.data.repProductList || [];
          this.tableData.map(p=>{
            //  p.maxPrice= JSON.parse(JSON.stringify(this.formatMoney(p.maxPrice)))
            //  p.minPrice= JSON.parse(JSON.stringify(this.formatMoney(p.minPrice)))
           })
          // 计算本轮结束和开始时间
          if (this.filterForm.bathType === '0') {
            res.data.time_range && this.countdown(res.data.time_range.endTime, this.endTime);
          } else if (this.filterForm.bathType === '1') {
            res.data.time_range && this.countdown(res.data.time_range.startTime, this.startTime);
          }
        }
      });
    },
    getQuteSuccessList() {
      getQuteSuccessList().then(res => {
        if (res.errno === 0) {
          this.quteSuccessList = res.data || [];
        }
      });
    },
    getProductHistoryList() {
      getProductHistoryList().then(res => {
        if (res.errno === 0) {
          let list = [];
          list = res.data.map(item => {
            item.list.forEach(p => {
              p.id = p.productId;
              p.productSpec = p.specName;
              p.reqNum = p.num;



            // p.maxPrice= JSON.parse(JSON.stringify(this.formatMoney(p.maxPrice)))
            // p.minPrice= JSON.parse(JSON.stringify(this.formatMoney(p.minPrice)))


            });
            return item;
          });
          this.tableData = list;
        }
      });
    },
  formatMoney(value) {
      //// 金额转换
      if (value !== undefined && value.toString().indexOf('.') !== -1) {
        if (value !== undefined && value.toString().split('.')[1].length == 2) {
          if (value !== undefined && value.toString().split('.')[0].length >= 9) {
            value = ((value / 100000000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '亿元');
          } 
          
        else  if ( value !== undefined && value.toString().split('.')[0].length == 8) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 7) + '万元');
          } 
          else  if ( value !== undefined && value.toString().split('.')[0].length ==7 ) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 6) + '万元');
          } 
          else  if ( value !== undefined && value.toString().split('.')[0].length ==6) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '万元');
          } 
          else  if ( value !== undefined && value.toString().split('.')[0].length ==5) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '万元');
          } 
          
        else if (value !== undefined && value.toString().split('.')[0].length <= 4) {
            return (value = value.toString() + '元');
          }
          /////
        } else if (value !== undefined && value.toString().split('.')[1].length == 1) {
          if (value !== undefined && value.toString().split('.')[0].length >= 9) {
            value = ((value / 100000000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '亿元');
          }
          else if (  value !== undefined && value.toString().split('.')[0].length ==8) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 6) + '万元');
          }      
          else if ( value !== undefined && value.toString().split('.')[0].length ==7) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '万元');
          }    
          else if ( value !== undefined && value.toString().split('.')[0].length ==6) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '万元');
          }  
          else if (value !== undefined && value.toString().split('.')[0].length ==5 ) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 3) + '万元');
          } 
          // else if (9 > value !== undefined && value.toString().split('.')[0].length > 4) {
          //   value = ((value / 10000) * 100) / 100;
          //   return (value = value.toString().slice(0, 4) + '万元');
          // } 
          
          else if (value !== undefined && value.toString().split('.')[0].length <= 4) {
            return (value = value.toString() + '元');
          }
        }

        //////////////
      } else if (value !== undefined && value.toString().length > 9) {
        value = ((value / 100000000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '亿元');
      } else if (value !== undefined && value.toString().length == 9) {
        value = ((value / 100000000) * 100) / 100;
        return (value = value.toString().slice(0, 4) + '亿元');
      } else if (value !== undefined && value.toString().length == 8) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 7) + '万元');
      } else if (value !== undefined && value.toString().length == 7) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 6) + '万元');
      } else if (value !== undefined && value.toString().length == 6) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '万元');
      } else if (value !== undefined && value.toString().length == 5) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '万元');
      } else if (value !== undefined && value.toString().length <= 4) {
        return (value = value + '元');
      }
    },
    toDetail(id, index) {
      this.currentActiveIndex = index;
      this.$router.push({
        path: '/home/LoogForDetail',
        query: {
          id
        }
      });
    },
    getAllCategory() {
      categoryAll().then(res => {
        this.categoryList = res.data || [];
        this.categoryList.unshift({id: '', name: '全部'});
      });
    },
    // 选择不同状态的tab
    selectStatus(val) {
      this.filterForm.bathType = val;
      this.refreshPage();
    },
    // 刷新
    refreshPage() {
      this.tableData = [];
      switch (this.filterForm.bathType) {
        case '0':
          this.getProductList();
          // this.isLogin && this.getQuteSuccessList();
          break;
        case '1':
          this.getProductList();
          break;
        case '2':
          this.getProductHistoryList();
          break;
      }
    },
    onSelectCategory(val) {
      this.pageManage.page = 1;
      this.filterForm.categoryId = val;
      this.getProductList();
    },
    changeCurrentPage(num) {
      this.pageManage.page = num;
      this.getProductList();
    },
    // 勾选商品
    selectProduct(product) {
      if (!this.selectProductIds.includes(product.id)) {
        this.selectProductIds.push(product.id);
        this.selectProductList.push(product);
      } else {
        const deleteIndex = this.selectProductIds.indexOf(product.id);
        this.selectProductIds.splice(deleteIndex, 1);
        this.selectProductList.splice(deleteIndex, 1);
      }

      sessionStorage.setItem('loogForQuoteProductList', JSON.stringify(this.selectProductList));
      sessionStorage.setItem('loogForQuoteProductIds', JSON.stringify(this.selectProductIds));
    },
    // 取消勾选
    cancelSelect() {
      this.selectProductList = [];
      this.selectProductIds = [];

      sessionStorage.setItem('loogForQuoteProductList', JSON.stringify(this.selectProductList));
      sessionStorage.setItem('loogForQuoteProductIds', JSON.stringify(this.selectProductIds));
    },
    // 显示报价窗口
    showQuoteDialog() {
      if(this.$refs.CFCARef.checkIsIE()){
        // 获取证书序列码
        if (!this.$refs.CFCARef.getCertInfo()) return;
        // 检测是否读取证书序列码
        if (!this.certInfo) return this.$message.info('未检测到证书信息');
      } else {
        this.$refs.CFCARef.getCertInfo()
        if (!this.certInfo) return ;
        //  this.$message.info('未检测到证书信息')
      }

      localStorage.setItem('certInfo', this.certInfo);

      if (!this.isLogin) {
        return this.$confirm('当前未登录, 是否登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({path: '/home/Login'});
        });
      }
      if (!this.selectProductIds.length) return this.$message.info('请勾选竞买商品！');
      this.selectMinPriceProduct = null;
      // 赛选最低价的产品
      this.selectProductList.forEach(item => {
        if (!this.selectMinPriceProduct) {
          this.selectMinPriceProduct = item;
        } else if (this.selectMinPriceProduct.minPrice > item.minPrice) {
          this.selectMinPriceProduct = item;
        }

      });
      this.quoteManage.quoteForm.price = this.selectMinPriceProduct.minPrice;
      this.quoteManage.miunsBtnDisable = false;
      this.quoteManage.addBtnDisable = true;
      this.quoteManage.quoteDialogVisible = true;
    },
    // 一键报价
    quoteBtn() {
      this.$refs.quoteForm.validate(valid => {
        if (valid) {
          let list = [];
          this.selectProductList.forEach(item => {
            list.push({
              productId: item.id,
              proNum: item.reqNum,
              amount: this.quoteManage.quoteForm.price
            });
          });
          addProductQuote(list).then(res => {
            if (res.errno === 0) {
              const idList = res.data || [];
              this.quoteManage.successNum = idList.length;
              this.quoteManage.fallNum = this.selectProductList.length - idList.length;
              idList.forEach(id => {
                const deleteIndex = this.selectProductIds.indexOf(id);
                if (deleteIndex > -1) {
                  this.selectProductIds.splice(deleteIndex, 1);
                  this.selectProductList.splice(deleteIndex, 1);
                }
              });
              sessionStorage.setItem('loogForQuoteProductList', JSON.stringify(this.selectProductList));
              sessionStorage.setItem('loogForQuoteProductIds', JSON.stringify(this.selectProductIds));
              this.quoteManage.quoteDialogVisible = false;
              this.quoteManage.quoteResultDialogVisible = true;
              this.quoteManage.closeResultTimer = setInterval(() => {
                this.quoteManage.closeResultTimeText--;
                if (this.quoteManage.closeResultTimeText <= 0) {
                  this.quoteManage.quoteResultDialogVisible = false;
                  clearInterval(this.quoteManage.closeResultTimer);
                  this.quoteManage.closeResultTimer = null;
                  this.quoteManage.closeResultTimeText = 10;
                }
              }, 1000);
              this.getProductList();
              // this.getQuteSuccessList();
            } else if (res.errno === -1) {
              this.getProductList();
              // this.getQuteSuccessList();
            }
          });
        }
      });
    },
    // 倒计时
    countdown(endTime, obj) {
      let timer = null;
      const end = Date.parse(formatNewDate(endTime));
      // 清除定时器
      if (obj.timer) {
        clearInterval(obj.timer);
        obj.timer = null;
      }
      const now = Date.parse(new Date());
      const msec = end - now;
      // 时间结束不执行
      if (msec <= 0) return;
      // 生成定时器
      timer = setInterval(() => {
        const now = Date.parse(new Date());
        const msec = end - now;
        let d = parseInt(msec / 1000 / 60 / 60 / 24);
        let h = parseInt((msec / 1000 / 60 / 60) % 24);
        let m = parseInt((msec / 1000 / 60) % 60);
        let s = parseInt((msec / 1000) % 60);
        obj.day = d;
        obj.hour = h > 9 ? h : '0' + h;
        obj.minute = m > 9 ? m : '0' + m;
        obj.second = s > 9 ? s : '0' + s;
        if (msec <= 0) {
          clearTimeout(timer);
          obj.timer = null;
          return;
        }
      }, 1000);
      obj.timer = timer;
    },
    // 关闭结果弹窗
    handleCloseResult() {
      clearInterval(this.quoteManage.closeResultTimer);
      this.quoteManage.closeResultTimer = null;
      this.quoteManage.closeResultTimeText = 10;
    },
    // 减去
    minusAmount() {
      let amount = Number(this.quoteManage.quoteForm.price);
      amount -= this.selectMinPriceProduct.stepPrice || 10;
      if (amount <= this.selectMinPriceProduct.minPrice * 0.7) {
        this.quoteManage.quoteForm.price = Number((Math.round(this.selectMinPriceProduct.minPrice * 70) / 100).toFixed(2));
        amount = Number((Math.round(this.selectMinPriceProduct.minPrice * 70) / 100).toFixed(2));
        if (amount < this.selectMinPriceProduct.minPrice * 0.7) {
          this.quoteManage.quoteForm.price += 0.01;
          amount += 0.01;
        }
        this.quoteManage.miunsBtnDisable = true;
      } else {
        this.quoteManage.quoteForm.price = Number((Math.round(amount * 100) / 100).toFixed(2));
        amount = Number((Math.round(amount * 100) / 100).toFixed(2));
        this.quoteManage.miunsBtnDisable = false;
      }
      if (amount >= this.selectMinPriceProduct.minPrice) {
        this.quoteManage.addBtnDisable = true;
      } else {
        this.quoteManage.addBtnDisable = false;
      }
    },
    // 加上
    addAmount() {
      let amount = Number(this.quoteManage.quoteForm.price);
      amount += this.selectMinPriceProduct.stepPrice || 10;
      if (amount >= this.selectMinPriceProduct.minPrice) {
        this.quoteManage.quoteForm.price = Number((Math.round(this.selectMinPriceProduct.minPrice * 100) / 100).toFixed(2));
        amount = Number((Math.round(this.selectMinPriceProduct.minPrice * 100) / 100).toFixed(2));
        this.quoteManage.addBtnDisable = true;
      } else {
        this.quoteManage.quoteForm.price = Number((Math.round(amount * 100) / 100).toFixed(2));
        amount = Number((Math.round(amount * 100) / 100).toFixed(2));
        this.quoteManage.addBtnDisable = false;
      }
      if (amount <= this.selectMinPriceProduct.minPrice * 0.7) {
        this.quoteManage.miunsBtnDisable = true;
      } else {
        this.quoteManage.miunsBtnDisable = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.loog-for-conteiner {
  background: #f5f5f5;
  width: 1200px;
  margin: 0 auto;

  .filter-content {
    .input-width {
      width: 300px;
    }
    .screen-status {
      display: flex;
      line-height: 30px;

      .tit {
        width: 64px;
        text-align: justify;
        text-align-last: justify;
      }
      .status-box {
        flex: 1;
        display: flex;
        align-items: center;

        p {
          cursor: pointer;
          padding: 0 10px;
          margin-right: 20px;
        }
      }
    }
    .status-tab {
      margin: 17px 0;
      padding: 0 41px;
      box-sizing: border-box;
      background: #fff;

      &-item {
        display: inline-block;
        margin-right: 52px;
        width: 66px;
        text-align: center;
        line-height: 42px;
        font-size: 14px;
        color: #666666;
        cursor: pointer;

        &.active {
          position: relative;
          color: #008cf4;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 2px;
            background: #008cf4;
          }
        }
      }
    }
  }
  .time-box {
    margin-bottom: 17px;
    text-align: center;
    line-height: 42px;
    font-size: 14px;
    color: #fff;
    background: rgb(255, 68, 71);
  }
  .table-content {
    .name {
      font-size: 14px;
      color: #222222;
    }
    .time {
      font-size: 12px;
      color: #999999;
    }
  }
  .title-wrap {
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 14px;
    color: #333;
    line-height: 42px;
    background: #fff;
  }
  .card-content {
    display: flex;
    flex-wrap: wrap;

    .card-item {
      position: relative;
      display: flex;
      margin: 0 14px 17px 0;
      padding: 24px 16px;
      box-sizing: border-box;
      width: 289px;
      border-radius: 3px;
      background: #fff;
      box-shadow: 0 0 0 1px #e3e3e3 inset;
      cursor: pointer;

      &:nth-of-type(4n) {
        margin-right: 0;
      }
      &.active {
        box-shadow: 0 0 0 1px #008cf4 inset;
      }
      .card-image {
        margin-right: 14px;
        width: 80px;
        height: 80px;
      }
      .card-info {
        flex: 1;

        &-title {
          font-size: 14px;
          color: #333;
        }
        &-item {
          display: flex;
          margin-top: 6px;
          font-size: 12px;
          color: #888;

          .title {
            width: 50px;
            text-align: justify;
            text-align-last: justify;
          }
          .text {
            flex: 1;
          }
          .price {
            flex: 1;
            position: relative;
            color: #008cf4;

            span {
              position: absolute;
              bottom: 0;
              font-size: 16px;
            }
          }
        }
      }
      .card-select {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 0;

        .select {
          width: 38px;
          height: 38px;
        }
        .done {
          width: 86px;
          height: 86px;
        }
      }
      .card-status {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 0;

        img {
          width: 56px;
          height: 56px;
        }
      }
    }
  }
  .history-card-content {
    .card-time {
      color: #333;
      line-height: 3em;
    }
    .card-list {
      display: flex;
      flex-wrap: wrap;

      .card-item {
        position: relative;
        display: flex;
        margin: 0 14px 17px 0;
        padding: 24px 16px;
        box-sizing: border-box;
        width: 289px;
        border-radius: 3px;
        background: #fff;
        box-shadow: 0 0 0 1px #e3e3e3 inset;
        cursor: pointer;

        &:nth-of-type(4n) {
          margin-right: 0;
        }
        &.active {
          box-shadow: 0 0 0 1px #008cf4 inset;
        }
        .card-image {
          margin-right: 14px;
          width: 80px;
          height: 80px;
        }
        .card-info {
          flex: 1;

          &-title {
            font-size: 14px;
            color: #333;
          }
          &-item {
            display: flex;
            margin-top: 6px;
            font-size: 12px;
            color: #888;

            .title {
              width: 50px;
              text-align: justify;
              text-align-last: justify;
            }
            .text {
              flex: 1;
            }
            .price {
              flex: 1;
              position: relative;
              color: #008cf4;

              span {
                position: absolute;
                bottom: 0;
                font-size: 16px;
              }
            }
          }
        }
        .card-select {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 0;

          .select {
            width: 38px;
            height: 38px;
          }
          .done {
            width: 86px;
            height: 86px;
          }
        }
      }
    }
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: inline-block;
      padding: 20px;
      box-sizing: border-box;
    }
    .quote-btn {
      display: inline-block;
      margin-right: 50px;
      padding: 0 20px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      height: 3em;
      line-height: 3em;
      background: #ff4400;
      cursor: pointer;

      &.no-select {
        background-image: linear-gradient(to right, #554f51, #5e5657);
        cursor: not-allowed;
      }
    }
  }
  .num-box {
    display: flex;
    align-items: center;
    border: 1px solid #e4e4e4;

    .min,
    .add {
      width: 34px;
      height: 34px;
      text-align: center;
      line-height: 34px;
      cursor: pointer;
      background: #f6f7fb;

      &.disable {
        cursor: not-allowed;
        background: #eeeeee;
      }
    }
    .num {
      width: 140px;
      border: 1px solid #e4e4e4;
      border-top: 0;
      border-bottom: 0;
      text-align: center;
    }
  }
  .rule-info {
    ul {
      margin: 0;
      padding: 0 10px 10px 22px;
      box-sizing: border-box;
      font-size: 16px;
      color: #333;
      line-height: 1.5em;
      list-style-type: decimal;

      li {
        margin-top: 0;
        margin-bottom: 8px;
      }
    }
  }
}

.right-btn {
  position: fixed;
  bottom: 50%;
  left: 50%;
  width: 60px;
  margin-bottom: -117px;
  margin-left: 616px;
  border: 1px solid #e8e9ed;
  border-radius: 4px;
  padding: 5px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .btn-item {
    width: 48px;
    height: 48px;
    padding: 8px;
    font-size: 14px;
    color: #555555;
    box-sizing: border-box;
    text-align: center;
    border-radius: 4px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.hover {
      background-image: linear-gradient(to bottom, rgb(45, 190, 255), rgb(5, 154, 249));
      color: #ffffff;
    }
    &.no-select {
      background-image: linear-gradient(to right, #554f51, #5e5657);
      cursor: not-allowed;
    }
    span {
      width: 100%;
    }
    i {
      font-size: 20px;
    }
  }
  .line {
    border-bottom: 1px solid #e8e9ed;
    margin: 5px 0;
  }
}
.status-box-all:hover,
.status-box-notice:hover,
.status-box-doing:hover {
  color: #008cf4;
}
</style>
