/**
 * 文本脱敏
 * @param {*} text 文本
 * @param {*} startNum 前几个
 * @param {*} endNum 后几个
 * @returns 
 */
export function hideTextInfo(text, startNum, endNum) {
    if (text) {
        text = String(text);
        return text.substr(0, startNum) + '***' + text.substr(text.length - endNum, text.length);
    } else {
        return '';
    }
}

// 格式化时间 (解决 new Date(val) 的兼容问题)
export function formatNewDate(date) {
    if (date) {
        let newDate = new Date();
        date = date.replace('T', ' ');

        // 兼容所有浏览器
        let d = date.split(" ")[0].split("-");
        newDate.setFullYear(d[0],d[1] - 1, d[2]);
        
        if (date.split(" ")[1]) {
            let t = date.split(" ")[1].split(":");
            newDate.setHours(t[0], t[1], t[2], 0);
        }
        return newDate;
    } else {
        return new Date();
    }
}