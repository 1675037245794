var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loog-for-conteiner" },
    [
      _c("div", { staticClass: "filter-content" }, [
        _c("div", { staticClass: "status-tab" }, [
          _c(
            "div",
            {
              staticClass: "status-tab-item",
              class: { active: _vm.filterForm.bathType === "0" },
              on: {
                click: function ($event) {
                  return _vm.selectStatus("0")
                },
              },
            },
            [_vm._v("正在进行")]
          ),
          _c(
            "div",
            {
              staticClass: "status-tab-item",
              class: { active: _vm.filterForm.bathType === "1" },
              on: {
                click: function ($event) {
                  return _vm.selectStatus("1")
                },
              },
            },
            [_vm._v("即将开始")]
          ),
          _c(
            "div",
            {
              staticClass: "status-tab-item",
              class: { active: _vm.filterForm.bathType === "2" },
              on: {
                click: function ($event) {
                  return _vm.selectStatus("2")
                },
              },
            },
            [_vm._v("历史批次")]
          ),
        ]),
      ]),
      _vm.filterForm.bathType === "0"
        ? _c("div", { staticClass: "time-box" }, [
            _vm.endTime.timer
              ? _c("span", [
                  _vm._v(" 本轮报价结束时间: "),
                  _vm.endTime.day > 0
                    ? _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm.endTime.day) + " "),
                      ])
                    : _vm._e(),
                  _vm.endTime.day > 0
                    ? _c("span", { staticClass: "unit" }, [_vm._v("天")])
                    : _vm._e(),
                  _c("span", { staticClass: "time" }, [
                    _vm._v(" " + _vm._s(_vm.endTime.hour) + " "),
                  ]),
                  _c("span", { staticClass: "unit" }, [_vm._v("时")]),
                  _c("span", { staticClass: "time" }, [
                    _vm._v(" " + _vm._s(_vm.endTime.minute) + " "),
                  ]),
                  _c("span", { staticClass: "unit" }, [_vm._v("分")]),
                  _c("span", { staticClass: "time" }, [
                    _vm._v(" " + _vm._s(_vm.endTime.second) + " "),
                  ]),
                  _c("span", { staticClass: "unit" }, [_vm._v("秒")]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.filterForm.bathType === "1"
        ? _c(
            "div",
            {
              staticClass: "time-box",
              staticStyle: { background: "rgb(8, 135, 242)" },
            },
            [
              _vm.startTime.timer
                ? _c("span", [
                    _vm._v(" 下轮报价开始时间: "),
                    _vm.startTime.day > 0
                      ? _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.startTime.day) + " "),
                        ])
                      : _vm._e(),
                    _vm.startTime.day > 0
                      ? _c("span", { staticClass: "unit" }, [_vm._v("天")])
                      : _vm._e(),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(" " + _vm._s(_vm.startTime.hour) + " "),
                    ]),
                    _c("span", { staticClass: "unit" }, [_vm._v("时")]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(" " + _vm._s(_vm.startTime.minute) + " "),
                    ]),
                    _c("span", { staticClass: "unit" }, [_vm._v("分")]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(" " + _vm._s(_vm.startTime.second) + " "),
                    ]),
                    _c("span", { staticClass: "unit" }, [_vm._v("秒")]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.filterForm.bathType !== "2"
        ? _c(
            "div",
            {
              staticClass: "card-content",
              staticStyle: { "padding-bottom": "55px" },
            },
            [
              _vm.tableData.length &&
              ((_vm.filterForm.bathType === "0" && _vm.endTime.timer) ||
                (_vm.filterForm.bathType === "1" && _vm.startTime.timer))
                ? _vm._l(_vm.tableData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "card-item",
                        class: {
                          active:
                            _vm.selectProductIds.includes(item.id) &&
                            _vm.filterForm.bathType === "0",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toDetail(item.id, index)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "card-image",
                          attrs: {
                            src:
                              item.picUrl ||
                              "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=587861744,2176912391&fm=26&gp=0.jpg",
                          },
                        }),
                        _c("div", { staticClass: "card-info" }, [
                          _c("div", { staticClass: "card-info-title" }, [
                            _vm._v(_vm._s(item.productName)),
                          ]),
                          _c("div", { staticClass: "card-info-item" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("规格型号"),
                            ]),
                            _vm._v(" ： "),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.productSpec)),
                            ]),
                          ]),
                          _c("div", { staticClass: "card-info-item" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("数量"),
                            ]),
                            _vm._v(" ： "),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.reqNum) + _vm._s(item.unit)),
                            ]),
                          ]),
                          _c("div", { staticClass: "card-info-item" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("求购价"),
                            ]),
                            _vm._v(" ： "),
                            _c("div", { staticClass: "price" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("toFixed")(item.maxPrice))
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "card-info-item" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("商家报价"),
                            ]),
                            _vm._v(" ： "),
                            _c("div", { staticClass: "price" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("toFixed")(item.minPrice))
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm.filterForm.bathType === "0"
                          ? _c(
                              "div",
                              {
                                staticClass: "card-status",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.selectProduct(item)
                                  },
                                },
                              },
                              [
                                item.curUserMin === 0
                                  ? _c("img", {
                                      staticClass: "select",
                                      attrs: {
                                        src: require("../image/icon-anewPrice.png"),
                                      },
                                    })
                                  : item.curUserMin === 1
                                  ? _c("img", {
                                      staticClass: "select",
                                      attrs: {
                                        src: require("../image/icon-lowestPrice.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm.filterForm.bathType === "0"
                          ? _c(
                              "div",
                              {
                                staticClass: "card-select",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.selectProduct(item)
                                  },
                                },
                              },
                              [
                                _vm.selectProductIds.includes(item.id)
                                  ? _c("img", {
                                      staticClass: "select",
                                      attrs: {
                                        src: require("../image/select.png"),
                                      },
                                    })
                                  : _c("img", {
                                      staticClass: "select",
                                      attrs: {
                                        src: require("../image/no-select.png"),
                                      },
                                    }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  })
                : _c("NoDataFound", {
                    staticStyle: { "margin-bottom": "60px" },
                  }),
            ],
            2
          )
        : _c(
            "div",
            {
              staticClass: "history-card-content",
              staticStyle: { "padding-bottom": "55px" },
            },
            [
              _vm.tableData.length
                ? _vm._l(_vm.tableData, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "card-batch" },
                      [
                        _c("div", { staticClass: "card-time" }, [
                          _vm._v(
                            _vm._s(item.bathTime.startTime) +
                              " - " +
                              _vm._s(item.bathTime.endTime)
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "card-list" },
                          _vm._l(item.list, function (p, pidx) {
                            return _c(
                              "div",
                              {
                                key: p.id,
                                staticClass: "card-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.toDetail(p.id, pidx)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "card-image",
                                  attrs: {
                                    src:
                                      p.picUrl ||
                                      "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=587861744,2176912391&fm=26&gp=0.jpg",
                                  },
                                }),
                                _c("div", { staticClass: "card-info" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card-info-title" },
                                    [_vm._v(_vm._s(p.productName))]
                                  ),
                                  _c("div", { staticClass: "card-info-item" }, [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("规格型号"),
                                    ]),
                                    _vm._v(" ： "),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(_vm._s(p.productSpec)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "card-info-item" }, [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("数量"),
                                    ]),
                                    _vm._v(" ： "),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(_vm._s(p.reqNum) + _vm._s(p.unit)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "card-info-item" }, [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("求购价"),
                                    ]),
                                    _vm._v(" ： "),
                                    _c("div", { staticClass: "price" }, [
                                      _vm._v(" ￥"),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm._f("toFixed")(p.maxPrice))
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "card-info-item" }, [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("商家报价"),
                                    ]),
                                    _vm._v(" ： "),
                                    _c("div", { staticClass: "price" }, [
                                      _vm._v(" ￥"),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm._f("toFixed")(p.minPrice))
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  })
                : _c("NoDataFound", {
                    staticStyle: { "margin-bottom": "60px" },
                  }),
            ],
            2
          ),
      _c(
        "div",
        { staticClass: "right-btn" },
        [
          [
            _c(
              "div",
              {
                staticClass: "btn-item hover",
                class: [_vm.selectProductIds.length ? "hover" : "no-select"],
                on: { click: _vm.showQuoteDialog },
              },
              [_c("span", [_vm._v("一键报价")])]
            ),
            _c("div", { staticClass: "line" }),
          ],
          [
            _c(
              "div",
              { staticClass: "btn-item", on: { click: _vm.cancelSelect } },
              [_c("span", [_vm._v("取消勾选")])]
            ),
            _c("div", { staticClass: "line" }),
          ],
          [
            _c(
              "div",
              {
                staticClass: "btn-item",
                on: {
                  click: function ($event) {
                    _vm.quoteManage.ruleInfoDialogVisible = true
                  },
                },
              },
              [_c("span", [_vm._v("规则须知")])]
            ),
            _c("div", { staticClass: "line" }),
          ],
          _c(
            "div",
            { staticClass: "btn-item", on: { click: _vm.refreshPage } },
            [
              _c("i", { staticClass: "el-icon-refresh" }),
              _c("span", [_vm._v("刷新")]),
            ]
          ),
        ],
        2
      ),
      _c("CFCATool", {
        ref: "CFCARef",
        attrs: { id: "CFCAId" },
        on: {
          getCertInfo: function ($event) {
            _vm.certInfo = $event
          },
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "规则须知",
            visible: _vm.quoteManage.ruleInfoDialogVisible,
            width: "520px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.quoteManage, "ruleInfoDialogVisible", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "rule-info" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  "竞买产品中买家提供产品的最高求购价，此后供应商在此价格的基础上进行减价形式的报价；"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "供应商所报价不能高于当前最低报价，否则视为报价不成功；"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "一键报价功能在选择了多个竞买产品时，按照所选产品中的报价最低的产品为标准，为所选产品的报价和价格调整幅度；"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "对于既是供应商又是买家的用户，用户不能给自己企业的产品报价，否则视为报价不成功；"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "竞买产品报价成功的结果为产品有效期内的最低报价，一次报价成功不代表产品成交；"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "竞买产品报价成功之后，系统将自动根据报价情况，在产品有效期结束之后选择最低报价成交，届时将会以短信通知的形式发送到用户预留的手机号。"
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "一键报价",
            visible: _vm.quoteManage.quoteDialogVisible,
            width: "380px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.quoteManage, "quoteDialogVisible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "quoteForm",
              staticStyle: { "text-align": "center" },
              attrs: {
                model: _vm.quoteManage.quoteForm,
                rules: _vm.quoteManage.quoteFormRules,
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "price", label: "报价(元):" } },
                [
                  _c("div", { staticClass: "num-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "min",
                        class: { disable: _vm.quoteManage.miunsBtnDisable },
                        on: { click: _vm.minusAmount },
                      },
                      [_c("i", { staticClass: "el-icon-minus" })]
                    ),
                    _c("div", { staticClass: "num", attrs: { type: "text" } }, [
                      _vm._v(_vm._s(_vm.quoteManage.quoteForm.price)),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "add",
                        class: { disable: _vm.quoteManage.addBtnDisable },
                        on: { click: _vm.addAmount },
                      },
                      [_c("i", { staticClass: "el-icon-plus" })]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.quoteManage.quoteDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.quoteBtn } },
                    [_vm._v("确认报价")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "报价结果",
            visible: _vm.quoteManage.quoteResultDialogVisible,
            width: "300px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.quoteManage,
                "quoteResultDialogVisible",
                $event
              )
            },
            closed: _vm.handleCloseResult,
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "font-size": "14px", "text-align": "center" } },
            [
              _vm._v(" 报价成功："),
              _c("span", { staticStyle: { color: "green" } }, [
                _vm._v(_vm._s(_vm.quoteManage.successNum) + " "),
              ]),
              _vm._v("件， 报价失败："),
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v(_vm._s(_vm.quoteManage.fallNum) + " "),
              ]),
              _vm._v("件 "),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.quoteManage.quoteResultDialogVisible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "关闭(" +
                          _vm._s(_vm.quoteManage.closeResultTimeText) +
                          "s)"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }