/**
 * 正则表达式检验规则
 * @export
 */
export const regRule = {
  regCode: /^\d{4}$/, // 4位数字
  regPhoneStrict: /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/, // 手机
  regPhone: /^[1][\d]{10}$/, // 手机
  REGEX_MOBILE_EXACT: /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(16[6])|(17[0,1,3,5-8])|(18[0-9])|(19[8,9]))\\d{8}$/,
  regFixedPhone: /(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}/, // 固定电话
  regPostalCode: /[1-9]{1}(\d+){5}/, // 邮政编码
  regPsd: /^[0-9A-Za-z]{6,20}$/, // 由数字、字母组成的6-20位的密码
  regPsd1: /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*])[a-z\d#@!~%^&*]/i, // 由数字字母特殊字符组成的密码，特殊字符有#@!~%^&*
  regPsd2: /^[a-zA-Z]\w{5,17}$/, // 以字母开头，长度在6~18之间，只能包含字母、数字和下划线
  regPsd3: /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/, // 强密码正则，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
  regPsd4: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, // 6-20位字母和数字组成的密码
  regPsd5: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,8}$/, // 6-8位字母和数字组成的密码
  regEmail: /^([A-Za-z0-9_\-.\u4e00-\u9fa5])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/, // 邮箱
  regEmail1:/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,//可用邮箱校验
  card: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, // 身份证
  regID2: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, // 第二代身份证号码正则
  regID1: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/, // 第一代身份证正则表达式(15位),
  number1: /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*))$/, // 正数的正则表达式(不包括0，小数保留两位)
  number2: /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*)|0)$/, // 正数的正则表达式(包括0，小数保留两位)
  number3: /^[+]{0,1}(\d+)$/, //  正整数的正则表达式(包括0)
  number4: /^[1-9]\d*$/, // 正整数的正则表达式(不包括0)
  number5: /^(0\.\d+|1)$/, // 折扣（0-1）的小数的正则表达式(包括1)
  number5: /^(0\.\d+)$/, // 折扣（0-1）的小数的正则表达式(不包括1)
}
